// Path: ./src/app/pages/FlyerTagListPage/index.tsx
/**
 *
 * FlyerTagListPage
 *
 */
import React, { memo, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { GfFlyerDto } from '@swagger/typescript-fetch-goflyer';
import { Spinner } from 'app/components/Spinner';
import { useHistory, useParams } from 'react-router-dom';
import { GoFlyerAppContext } from 'app/store/context';
import {
  checkIfStoreInFavList,
  getMyDistanceToStore,
  getFlyerStatusString,
  instanceOfFlyer,
  getSmallImageIfExist,
} from 'utils/dtoUtility';
import InfiniteScroll from 'react-infinite-scroller';
import { CardFlyerNew } from 'app/components/CardFlyerNew';
import {
  setLocation,
  addFlyerTagFlyersData,
  updateFlyerTagFlyersData,
} from 'app/store/reducer';
import { DownloadBanner } from 'app/components/DownloadBanner';
import { Capacitor } from '@capacitor/core';
import { NoResultsReturn } from 'app/components/NoResultsReturn';
import * as analytics from '../../../utils/analytics';
import { FlyerTagListId } from 'utils/FlyerTagListId';
import PullToRefresh from 'react-simple-pull-to-refresh';
import {
  gfStoreControllerFindFlyersByNearbyStores,
  gfStoreControllerFindMenusByNearbyStores,
} from 'utils/cacheRequest';
import { useClickFavMobileFunc } from 'utils/commonFunctions/ClickFavButtonMobile';
import { messages } from 'locales/messages';
import { Position } from '@capacitor/geolocation';
import { getPosition } from 'utils/getUserLocation';
import CustomHelmet from 'app/components/Helmet';
import { GetUserLocation } from 'utils/LocationCheck';
import { useNormalizedTag } from 'utils/hook/useNormalizedTag';
import { useAdvertisementBanner } from 'utils/hook/useAdvertisementBanner';

export const FlyerTagListPage = memo(() => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const params: {
    tag: string;
  } = useParams();
  const [hasMore, setHasMore] = React.useState<boolean>(true);
  const { state, dispatch } = React.useContext(GoFlyerAppContext);
  const [showBanner, setShowBanner] = React.useState(false);
  const [waitingUI, setWaitingUI] = React.useState(
    <>
      <Spinner></Spinner>
    </>,
  );
  const [refresh, setRefresh] = React.useState(false);
  const normalizedTag = useNormalizedTag(params.tag);
  const AdvertisementBanner = useAdvertisementBanner('mobile');

  React.useEffect(() => {
    if (!state.flyerTagList[normalizedTag]) {
      dispatch(addFlyerTagFlyersData(normalizedTag));
    }

    analytics.view_flyer_tag_page(normalizedTag);

    if (window !== undefined) {
      setTimeout(() => {
        window?.scrollTo({
          top: state.flyerTagList[normalizedTag]?.position || 0,
        });
      }, 1);
    }
    if (Capacitor.isNativePlatform()) {
      setShowBanner(false);
    } else {
      setShowBanner(true);
    }
  }, [normalizedTag]);

  const UpdateFlyer = (RestaurantResultList, calledByRefresh = false) => {
    if (RestaurantResultList?.results?.length !== 0) {
      setHasMore(true);
    }

    const sortedRestaurantResponse: GfFlyerDto[] =
      RestaurantResultList?.results?.sort((a: GfFlyerDto, b: GfFlyerDto) => {
        if (a.stores[0]?.rank && !b.stores[0]?.rank) {
          return a.stores[0]?.rank;
        }
        if (b.stores[0]?.rank && !a.stores[0]?.rank) {
          return b.stores[0]?.rank;
        }
        if (b.stores[0]?.rank && a.stores[0]?.rank) {
          return a.stores[0]?.rank - b.stores[0]?.rank;
        }
        return 0;
      });

    if (calledByRefresh) {
      dispatch(
        addFlyerTagFlyersData(normalizedTag, {
          flyer: sortedRestaurantResponse,
          position: 0,
          number: 1,
        }),
      );
    } else {
      dispatch(
        addFlyerTagFlyersData(normalizedTag, {
          flyer: [
            ...(state.flyerTagList[normalizedTag]?.flyer || []),
            ...sortedRestaurantResponse,
          ],
          position: state.flyerTagList[normalizedTag]?.position || 0,
          number: state.flyerTagList[normalizedTag]?.number || 1,
        }),
      );
    }

    if (RestaurantResultList?.results?.length > 0) {
      setWaitingUI(
        <>
          <Spinner></Spinner>
        </>,
      );
    } else {
      setWaitingUI(
        <NoResultsReturn
          content={
            <div>
              {t(messages.THERE_ARE_NO_FLYERS())} <br />
              {t(messages.IN_THIS_CATEGORY())}
            </div>
          }
        ></NoResultsReturn>,
      );
    }
  };
  const getFlyersTagList = async (
    page,
    FlyerTag,
    reFetchPosition = false,
    calledByRefresh = false,
  ) => {
    let location = state.location;
    if (reFetchPosition) {
      const position: Position = await GetUserLocation(true);
      console.log(`pull to refresh new location`, position);
      location = position;
      dispatch(setLocation(position));
    }
    dispatch(
      updateFlyerTagFlyersData(FlyerTag, {
        number: page,
      }),
    );
    setHasMore(false);
    const restaurantLimit = 20;

    const tagConfigs = {
      restaurant: {
        fetch: () =>
          gfStoreControllerFindMenusByNearbyStores(
            page,
            restaurantLimit,
            location?.coords.longitude.changeDecimal(3),
            location?.coords.latitude.changeDecimal(3),
          ),
      },
    };
    const tagConfig = tagConfigs[FlyerTag] || {
      fetch: async () => {
        const foundTag = state.flyerTags.find(
          tag =>
            tag.name.toLowerCase()?.replace(/\s+/g, '') ===
            FlyerTag.toLowerCase().replace(/\s+/g, ''),
        );
        if (foundTag) {
          return gfStoreControllerFindFlyersByNearbyStores(
            page,
            location?.coords.longitude.changeDecimal(3),
            location?.coords.latitude.changeDecimal(3),
            restaurantLimit,
            foundTag.id,
          );
        } else {
          history.push('/flyers');
        }

        return { results: [] };
      },
    };

    if (tagConfig) {
      const FlyersResultList = await tagConfig.fetch();
      UpdateFlyer(FlyersResultList, calledByRefresh);
    } else {
      history.push('/flyers');
    }
  };

  async function loadFunc(_page) {
    getFlyersTagList(_page, normalizedTag);
  }

  useEffect(() => {
    setWaitingUI(
      <>
        <Spinner></Spinner>
      </>,
    );
    getFlyersTagList(
      state?.flyerTagList[`${normalizedTag}`]?.number,
      normalizedTag,
    );
  }, [params?.tag]);

  const clickFavButton = useClickFavMobileFunc(
    analytics.click_fav_button_in_flyer_card,
    true,
  );

  const FlyerTagList = state?.flyerTagList[`${normalizedTag}`]?.flyer?.map(
    (flyerTagList, key) => {
      if (instanceOfFlyer(flyerTagList)) {
        const store_in_fav_list = checkIfStoreInFavList(
          flyerTagList.stores[0],
          state.customer.gfStoreFavouriteList,
        );

        return (
          <React.Fragment key={key}>
            <CardFlyerNew
              data-testid="flyer tag list page card flyer new"
              startingDate={flyerTagList.validStartDate}
              Logo={
                flyerTagList?.stores[0]?.merchant?.merchantMapLargeLogo
                  ? flyerTagList?.stores[0]?.merchant?.merchantMapLargeLogo.src
                  : flyerTagList?.stores[0]?.merchant?.merchantLogo.src
              }
              distance={getMyDistanceToStore(
                flyerTagList?.stores[0],
                state?.location,
              )}
              signature={flyerTagList.stores[0]?.signature}
              name={flyerTagList.stores[0]?.merchant.name}
              // description={flyerTagList.stores[0]?.merchant.description}
              storeFavList={store_in_fav_list}
              LeftImage={getSmallImageIfExist(
                flyerTagList.gfImages?.[0],
                state.remoteConfig.getSmallImagesCondition,
              )}
              RightImage={getSmallImageIfExist(
                flyerTagList.gfImages?.[1],
                state.remoteConfig.getSmallImagesCondition,
              )}
              daysLeft={
                normalizedTag === 'restaurant'
                  ? undefined
                  : getFlyerStatusString(flyerTagList)
              }
              price={flyerTagList.totalSave}
              onClick={e => {
                clickFavButton(
                  e,
                  flyerTagList.stores[0],
                  store_in_fav_list !== undefined,
                );
              }}
              bottomLink={{
                path: `/${
                  normalizedTag === 'restaurant'
                    ? 'restaurantview'
                    : 'flyerview'
                }/${flyerTagList?.id}-${flyerTagList?.stores[0]?.merchant?.name
                  .replace(/\s+/g, '-')
                  .toLowerCase()}-flyer`,
                state: {
                  storeId: flyerTagList.stores[0].id,
                },
              }}
              topLink={{
                path: `/storedetails`,
                search: `storeId=${flyerTagList.stores[0]?.id}`,
                storeId: `${flyerTagList.stores[0]?.id}`,
              }}
            />
          </React.Fragment>
        );
      }
    },
  );

  if (FlyerTagList !== undefined && showBanner === true) {
    for (let i = 1; i < FlyerTagList.length; i++) {
      if (i % 10 === 0) {
        FlyerTagList?.splice(
          i,
          0,
          <DownloadBanner key={Math.random()} inFlyer={true} />,
        );
      }
    }
  }
  const handleRefresh = async () => {
    setRefresh(true);
    await getFlyersTagList(1, normalizedTag, true, true);
    setRefresh(false);
  };

  return (
    <>
      <CustomHelmet
        title={`Goflyer - ${
          messages[normalizedTag] ? t(messages[normalizedTag]()) : params.tag
        }`}
        image={
          state?.flyerTagList[normalizedTag]?.flyer?.[0]?.gfImages?.[0]?.src ??
          ''
        }
        description={t(
          (
            messages[
              (normalizedTag + 'Description') as keyof typeof messages
            ] || messages.homeDescription
          )(),
        )}
        keywords={t(
          (
            messages[(normalizedTag + 'Keywords') as keyof typeof messages] ||
            messages.homeKeywords
          )(),
        )}
      />
      <FlyerTagListContainer id="FlyerTagListPage">
        <PullToRefresh
          onRefresh={handleRefresh}
          pullingContent={<></>}
          className="PullRefreshCss"
        >
          {!refresh ? (
            <InfiniteScroll
              key={`${normalizedTag}-scrollKey`}
              css={{
                marginTop: '-95px',
                paddingBottom: '20px',
              }}
              threshold={1000}
              initialLoad={false}
              pageStart={state?.flyerTagList[`${normalizedTag}`]?.number}
              loadMore={loadFunc.bind(this)}
              hasMore={hasMore}
              loader={
                <div
                  style={{ textAlign: 'center', paddingBottom: '20px' }}
                  key={0}
                >
                  Loading ...
                </div>
              }
            >
              {FlyerTagList?.length === 0 ? (
                waitingUI
              ) : (
                <FlyerTagListWrapper>
                  {AdvertisementBanner}
                  {FlyerTagList}
                </FlyerTagListWrapper>
              )}
            </InfiniteScroll>
          ) : (
            <Spinner />
          )}
        </PullToRefresh>
      </FlyerTagListContainer>
    </>
  );
});

const FlyerTagListContainer = styled.div`
  margin-top: 95px;
  margin-bottom: 70px;
  padding-bottom: 92px;
`;
const FlyerTagListWrapper = styled.div`
  margin-top: 123px;
`;
