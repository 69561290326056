import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { GfImage } from '@swagger/typescript-fetch-goflyer';
import { apiService } from 'utils/api';

import {
  EmptyStateContainer,
  LoadingContainer,
  NonPaginatedContainer,
  GridContainer,
  FlyerImage,
} from './styled';

interface Props {
  storeIds: string[];
  limit?: number;
}

const NonPaginatedFlyers: React.FC<Props> = ({ storeIds, limit }) => {
  const [images, setImages] = useState<GfImage[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleImageClick = (index: number) => {
    window.parent.postMessage(
      {
        type: 'openFlyerLightbox',
        payload: {
          images,
          currentImageIndex: index,
        },
      },
      '*',
    );
  };

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await Promise.all(
          storeIds?.map(async storeId => {
            return await apiService.gfFlyerControllerGetAllFlyerByStoreID(
              storeId,
              1,
              limit,
            );
          }),
        );
        const result =
          response
            ?.map(val => val.results)
            .flat()
            ?.map(val => val?.gfImages || [])
            ?.flat() || [];
        setImages(result);
        setError(null);
      } catch (err) {
        setError('Failed to load flyer . Please try again later.');
        console.error('Error fetching flyer:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [storeIds, limit]);

  useEffect(() => {
    const updateIframeHeight = () => {
      try {
        window.parent.postMessage(
          {
            type: 'resizeFlyer',
            payload: {
              height: containerRef.current?.scrollHeight || 0,
            },
          },
          '*',
        );
      } catch (error) {
        console.error('Error updating iframe height:', error);
      }
    };
    const handleMessage = (event: MessageEvent) => {
      if (event.data?.type === 'containerResizeFlyer') {
        updateIframeHeight();
      }
    };

    window.addEventListener('message', handleMessage);
    const resizeObserver = new ResizeObserver(updateIframeHeight);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    updateIframeHeight();

    return () => {
      window.removeEventListener('message', handleMessage);
      resizeObserver.disconnect();
    };
  }, [images?.length]);

  const renderContent = () => {
    if (error) {
      return (
        <EmptyStateContainer>
          <Typography color="error" variant="h6">
            {error}
          </Typography>
        </EmptyStateContainer>
      );
    }

    if (loading) {
      return (
        <LoadingContainer>
          <CircularProgress />
          <Typography variant="body1" sx={{ mt: 2 }}>
            Loading flyers...
          </Typography>
        </LoadingContainer>
      );
    }

    if (images.length === 0) {
      return (
        <EmptyStateContainer>
          <Typography variant="h6" color="text.secondary">
            No Flyers found
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
            There are no Flyers available at the moment.
          </Typography>
        </EmptyStateContainer>
      );
    }

    return (
      <NonPaginatedContainer>
        <Box sx={{ p: 2 }}>
          <GridContainer>
            {images?.map((image, index) => (
              <div
                key={image.id}
                onClick={() => handleImageClick(index)}
                style={{ cursor: 'pointer', width: '100%' }}
              >
                <FlyerImage src={image.src} alt={image.alt || 'Flyer image'} />
              </div>
            ))}
          </GridContainer>
        </Box>
      </NonPaginatedContainer>
    );
  };

  return <div ref={containerRef}>{renderContent()}</div>;
};

export default NonPaginatedFlyers;
