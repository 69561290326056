import React from 'react';
import { useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import { EmptyStateContainer } from './styled';
import NonPaginatedFlyers from './NonPaginatedFlyers';

const IframeFlyers: React.FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const storeIds = params.get('storeIds')?.split(',').filter(Boolean) || [];
  const limit = parseInt(params.get('limit') || '100', 10);

  if (!storeIds.length) {
    return (
      <EmptyStateContainer>
        <Typography variant="h6" color="error">
          Store Details Not Provided
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
          Please provide store IDs to view the items.
        </Typography>
      </EmptyStateContainer>
    );
  }

  return <NonPaginatedFlyers storeIds={storeIds} limit={limit} />;
};

export default IframeFlyers;
