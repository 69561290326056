import React from 'react';
import styled from 'styled-components';

const BaseBannerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const DesktopBannerWrapper = styled(BaseBannerWrapper)`
  margin: 0 auto 20px;
`;

const MobileBannerWrapper = styled(BaseBannerWrapper)`
  padding: 0 10px;
`;

const BannerImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 20px;
`;

interface BannerProps {
  src: string;
  alt?: string;
  isMobile?: boolean;
}

const AdvertisementBanner: React.FC<BannerProps> = ({
  src,
  alt = 'Banner Image',
  isMobile = false,
}) => {
  if (!src) {
    console.error('Banner component requires a valid src prop.');
    return <></>;
  }

  const Wrapper = isMobile ? MobileBannerWrapper : DesktopBannerWrapper;

  return (
    <Wrapper>
      <BannerImage src={src} alt={alt} />
    </Wrapper>
  );
};

export default AdvertisementBanner;
