import React from 'react';
import { useBannerHook } from 'utils/hook/useBannerHook';
import AdvertisementBanner from '../../../app/mobile-desktop-common-components/BannerImage';

export const useAdvertisementBanner = (deviceType: 'desktop' | 'mobile') => {
  const { data } = useBannerHook(deviceType);

  if (!data?.src) {
    return <></>;
  }

  return (
    <AdvertisementBanner src={data.src} isMobile={deviceType === 'mobile'} />
  );
};
