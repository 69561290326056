import { useState, useEffect, useCallback } from 'react';
import { GfImage } from '@swagger/typescript-fetch-goflyer';
import { apiService } from 'utils/api';

export const useBannerHook = (initialKey: string) => {
  const [key, setKey] = useState(initialKey);
  const [data, setData] = useState<GfImage>();
  const [loading, setLoading] = useState<boolean>(true);

  const FetchBannerData = useCallback(
    async (newKey?: string) => {
      try {
        setLoading(true);
        const filter = newKey ? [`key||eq||${newKey}`] : undefined;

        const response =
          await apiService.getManyBaseGfMiscellaneousControllerGfMiscellaneous(
            undefined,
            undefined,
            filter,
            undefined,
            undefined,
            undefined,
            1,
            undefined,
            1,
            undefined,
          );

        const item = response.data[0];

        if (item) {
          try {
            setData(JSON.parse(item.value));
          } catch {
            setData(item.value);
          }
        } else {
          setData(undefined);
        }
        if (newKey) {
          setKey(newKey);
        }
      } catch (err) {
        console.error(err);
        setData(undefined);
      } finally {
        setLoading(false);
      }
    },
    [key],
  );

  useEffect(() => {
    if (key) {
      FetchBannerData(key);
    }
  }, [key, FetchBannerData]);

  return { data, key, loading, FetchBannerData };
};
