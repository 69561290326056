import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import comStyled from 'styled-components';

export const NonPaginatedContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  overflowX: 'hidden',
  width: '100%',
  height: '100%',
  borderRadius: '8px',

  '&::-webkit-scrollbar': {
    width: 8,
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: '#555',
    },
  },
});

export const LoadingContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '4rem',
  width: '100%',
  height: '100%',
  background: '#fff',
  zIndex: 10,
});

export const EmptyStateContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '4rem',
  width: '100%',
  height: '100%',
  background: '#fff',
  zIndex: 10,
});

export const GridContainer = comStyled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const FlyerImage = comStyled.img`
  width: 100%;
  height: 100%;
`;
